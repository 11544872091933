import { createApiRef } from '@backstage/core-plugin-api';

/**
 * Represents a package definition, which includes the name and version of a package.
 */
export interface PackageDefinition {
    name: string;
    version: string;
}

/**
 * Represents a project definition, which includes an ID, file name, target framework, Azure Function version, and a list of package definitions.
 */
export interface DotNetProjectDefinition {
    id: string;
    fileName: string;
    targetFramework?: string;
    azureFunctionVersion?: string;
    packages: PackageDefinition[];
}

/**
 * Represents a Node.js project definition.
 */
export interface NodeProjectDefinition {
    id: string;
    fileName: string;
    version: string;
    dependencies: PackageDefinition[];
}

/**
 * Represents the end of life information for a target framework.
 */
export interface EndOfLife {
    cycle: string;
    lts: boolean;
    releaseDate: string;
    latestReleaseDate: string;
    support: string;
    eol: string;
    latest: string;
    link: string;
}

export const projectPackagesApiRef = createApiRef<ProjectPackagesApi>({
    id: 'plugin.project-packages.service',
});

/**
 * Represents the API for managing project packages.
 */
export type ProjectPackagesApi = {
    /**
     * Retrieves the definitions of .NET projects.
     * @param options - The options to use when retrieving the definitions.
     * @returns A promise that resolves to an array of .NET project definitions, or undefined if an error occurred.
     */
    getDotNetProjectsDefinition(options: {
        componentRef?: string,
        pattern?: string
    }): Promise<Array<DotNetProjectDefinition>> | undefined;

    /**
     * Retrieves the definitions of Node.js projects.
     * @param options - The options to use when retrieving the definitions.
     * @returns A promise that resolves to an array of Node.js project definitions, or undefined if an error occurred.
     */
    getNodeProjectsDefinition(options: {
        componentRef?: string,
        pattern?: string
    }): Promise<Array<NodeProjectDefinition>> | undefined;

    /**
     * Retrieves the end of life information for a target framework.
     * @param options - The options to use when retrieving the end of life information.
     * @returns A promise that resolves to the end of life information, or undefined if an error occurred.
     */
    getDotNetEndOfLife(options: {
        targetFramework: string
    }): Promise<EndOfLife> | undefined;

    /**
     * Retrieves the end of life information for a target framework.
     * @param options - The options to use when retrieving the end of life information.
     * @returns A promise that resolves to the end of life information, or undefined if an error occurred.
     */
    getNodeEndOfLife(options: {
        product: string,
        targetFramework: string
    }): Promise<EndOfLife> | undefined;

    /**
     * Retrieves the frameworks of the projects.
     * @param options - The options to use when retrieving the frameworks.
     * @returns A promise that resolves to the frameworks of the projects, or undefined if an error occurred.
     */
    getProjectsFrameworks(options: {
        componentRef?: string
    }): Promise<{ hasDotnetProjects: boolean, hasNodeProjects: boolean }> | undefined;
}